import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import rootRouterConfig from "../pages/routes";
import RouteSwitch from "./RouteSwitch";
import AppSidebar from "./AppSidebar";
import AppHeader from "./AppHeader";
import dispatchRouterConfig from "../pages/dispatch/routes";
import simRouterConfig from "../pages/sim/routes";
import dataRouterConfig from "../pages/data/routes"
// import supportRouterConfig from "../pages/support/routes";
import policyRouterConfig from "../pages/policy/routes";
import AppLoadingScreen from "./AppLoadingScreen";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { clearToast, selectApp } from "../slice/appSlice";
import { CXToast, CXToaster } from "./CXForm";
import dispatchUORouterConfig from "../pages/uo/routes";
import dataConfigRouterConfig from "../pages/dataConfig/routes";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const PageLayout = () => {
  const dispatch = useAppDispatch();
  const { isLoading, isBackgroundLoading, toast } = useAppSelector(selectApp);
  const toastComponent =
    !!toast?.type && !!toast.message ? (
      <CXToast type={toast.type} message={toast.message} className={toast?.className} />
    ) : (
      undefined
    );
  const rootRoutes = [
    ...dispatchRouterConfig,
    ...simRouterConfig,
    ...dispatchUORouterConfig,
    ...dataRouterConfig,
    ...dataConfigRouterConfig,
    ...rootRouterConfig,
    // ...supportRouterConfig,
    ...policyRouterConfig,
  ];

  useEffect(() => {
    if (toast) {
      dispatch(clearToast());
    }
  });

  return (
    <Router>
      <ScrollToTop />
      {isLoading && !isBackgroundLoading && <AppLoadingScreen />}
      <CXToaster toast={toastComponent} />
      <AppHeader />
      <div style={{ position: "relative" }}>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <div className="body">
            <RouteSwitch routes={rootRoutes} />
          </div>
        </div>
      </div>
    </Router>
  );
};

export default PageLayout;
