import React, { useEffect, useReducer, useRef, useState } from 'react';
import { CXButton, CXDropDown, CXTextInput } from '../../components/CXForm';
import { Form, Modal, Nav, Tab, } from 'react-bootstrap';
import { CRow, CCol, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody } from '@coreui/react-pro';
import { useHistory, useLocation, Link } from 'react-router-dom';
import ViewLabel from '../../components/ViewLabel';
import '@coreui/coreui/dist/css/coreui.min.css'
import { initialNewPortForm, initialPortReducer, initialPortTabContent, IPortPayload, IPortTab } from '../../slice/dispatcherSlice';
import DragContainer from '../../components/FlightPlanConfig/DragContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PortPageContentContainer from '../../components/FlightPlanConfig/PortPageContentContainer';
import PortPageAttachmentContainer from '../../components/FlightPlanConfig/PortPageAttachmentContainer';
import { getPort, getPorts, postCreateNewPort, postSavePortElement } from '../../services/portAPI';
import { selectUser } from '../../slice/userSlice';
import { useAppSelector } from '../../app/hooks';
import { getPortAttachment } from '../../services/api';
import cloneDeep from 'lodash/cloneDeep';
import PortPageRunway from './PortPageRunway';
import NoDataTableItem from '../../components/AdminDashboard/NoDataTableItem';
import PortListTableItem from '../../components/AdminDashboard/PortListTableItem';
import { AlertIcon } from '../../assets/images';
import { AccessType } from '../../constants/roleAccessConstant';
import Access from '../../components/Access';

const PortPageNotamPage = () => {
  const location = useLocation();
  const routePath = location.pathname;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const history = useHistory();
  let query = useQuery();

  const airlineQuery = query.get('airline') || "CPA";
  const searchQuery = query.get('search') || "";

  const { userId } = useAppSelector(selectUser);

  //search form
  const [airline, setAirline] = useState<string>(airlineQuery);
  const [airportName, setAirportName] = useState<string>(searchQuery);
  const [path, setPath] = useState<string>('');
  const [linkSearch, setLinkSearch] = useState<string>("");

  //Tab
  const [tabKey, setTabKey] = useState<number>(0);

  const [isSearchPort, setIsSearchPort] = useState<boolean>(false);
  const [resultFound, setResultFound] = useState<boolean>(true);

  const [isNewPortModal, setIsNewPortModal] = useState<boolean>(false);
  const [isEditTabModal, setIsEditTabModal] = useState<boolean>(false);
  const [isSaveReminder, setIsSaveReminder] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(true);

  //runway
  const [isViewRunway, setIsViewRunway] = useState<boolean>(false);
  //port list
  const [isSearchPorts, setIsSearchPorts] = useState<boolean>(false);
  const [portList, setPortList] = useState<any>({});
  const tabsRef = useRef<any>();
  const [originalPort, setOriginalPort] = useState<any>({ ...initialPortReducer });

  //Create new port Modal
  const [newPortForm, setNewPortForm] = useState<IPortPayload>({
    ...initialNewPortForm,
    galacxyId: userId,
    createdBy: userId
  });

  const onChangeField = (value: any, field: string, state: any, setState: Function) => {
    let tempObj = {
      ...state
    }
    tempObj[field] = value;
    setState(tempObj);
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr)
    const mon = date.toDateString().split(' ')[1]
    const mins = ('0'+ date.getMinutes()).slice(-2);
    return `${date.getDate()} ${mon} ${date.getFullYear()} ${date.getHours()}:${mins}`
  }

  const airlineOptions = [
    {
      text: "CPA",
      value: "CPA"
    },
    {
      text: "AHK",
      value: "AHK"
    },
    {
      text: "HKE",
      value: "HKE"
    }
  ]

  const closeModal = () => {
    setIsNewPortModal(false);
    setIsEditTabModal(false);
    setIsSaveReminder(false);
  }

  const showResultNotFound = (isSearchPort || isSearchPorts) && !resultFound;
  const showPortPageBar = (isSearchPort || isViewRunway) && resultFound;
  const showPortContent = isSearchPort && resultFound && !isViewRunway;
  const resultNotFoundContent = isSearchPort ? 'Port Not Found' : 'Ports Not Found';

  const reducer = (state, action) => {
    let editTabs = [...state.tabs];
    switch (action.type) {
      case "LOAD":
        const tabSnap = [...action.port.tabs];
        const newState = {
          ...state,
          airline: action.port.airline,
          cityName: action.port.cityName,
          name: action.port.name,
          countryName: action.port.countryName,
          airportName: action.port.name,
          iata: action.port.iata,
          icao: action.port.icao,
          originalTabs: tabSnap,
          tabs: action.port.tabs,
          version: action.port.version,
          createdBy: action.port.createdBy,
          createdAt: formatDate(action.port.createdAt),
        }
        const cloneState = cloneDeep(newState);
        setOriginalPort(cloneState);
        return newState;
      case "NO_SAVE_LOAD":
        const tabSnapLoad = [...action.port.tabs];
        const newStateLoad = {
          ...state,
          airline: action.port.airline,
          cityName: action.port.cityName,
          name: action.port.name,
          countryName: action.port.countryName,
          airportName: action.port.name,
          iata: action.port.iata,
          icao: action.port.icao,
          originalTabs: tabSnapLoad,
          tabs: action.port.tabs,
          version: action.port.version,
          createdBy: action.port.createdBy,
          createdAt: formatDate(action.port.createdAt),
        }
        return newStateLoad;
      case "CHANGE_SEC_TITLE":
        editTabs[action.tabIndex].secs[action.index].name = action.value;
        return {
          ...state,
          tabs: editTabs
        };
      case "RE_ORDER_TABS":
        editTabs = action.tabs;
        return {
          ...state,
          tabs: editTabs
        };
      case "RE_ORDER_SECS":
        editTabs[action.tabIndex].secs = action.tabSecs;
        return {
          ...state,
          tabs: editTabs
        };
      case "ADD_SEC":
        if (!editTabs[action.tabIndex].secs[action.len]) {
          let newSection = cloneDeep(initialPortTabContent);
          editTabs[action.tabIndex].secs.push(newSection);
        }
        return {
          ...state,
          tabs: editTabs
        };
      case "REMOVE_SEC":
        editTabs[action.tabIndex].secs.splice(action.secIndex, 1);
        return {
          ...state,
          tabs: editTabs
        };
      case "REMOVE_ATTACHMENT":
        editTabs[action.tabIndex].secs[0].items[0].files.splice(action.fileIndex, 1);
        return {
          ...state,
          tabs: editTabs
        };
      case "RESET":
        const tempState = cloneDeep(originalPort);
        return tempState;
      default:
        return state;
    }
  };


  const [portModal, dispatchReducer] = useReducer(reducer, initialPortReducer);

  const portInfo = {
    galacxyId: userId,
    airline: portModal.airline,
    icao: portModal.icao
  }

  const handleGetPort = () => {
    let tempObj = {};
    if (airline) {
      tempObj['airline'] = airline;
    }
    const tempSearchPort = linkSearch ? linkSearch : airportName ? airportName : "";
    if (tempSearchPort) {
      tempObj['airport'] = tempSearchPort.toUpperCase();
    }

    query.set('airline', airline);
    query.set('search', tempSearchPort.toUpperCase());
    const params = new URLSearchParams({ 'airline': airline, 'search': tempSearchPort.toUpperCase() });
    history.replace({ pathname: location.pathname, search: params.toString() });

    setPath(`${location.pathname}?${params.toString()}`);

    getPort(tempObj).then((res) => {
      setIsSearchPort(true);
      setIsViewRunway(false);
      setIsSearchPorts(false);
      if (res?.status === 500) {
        setResultFound(false);
      } else if (res) {
        dispatchReducer({ type: "LOAD", port: res });
        setResultFound(true);
      }

    }).catch((err) => {
      console.log(err);
    });
  }

  const handleGetPorts = () => {
    let tempObj = {};
    if (airline) {
      tempObj['airline'] = airline;
    }

    query.set('airline', airline);
    const params = new URLSearchParams({ 'airline': airline });
    history.replace({ pathname: location.pathname, search: params.toString() });
    setPath(`${location.pathname}?${params.toString()}`);

    //call api to get port list
    getPorts(tempObj).then((res) => {
      setIsSearchPorts(true);
      setIsViewRunway(false);
      setIsSearchPort(false);
      if (res?.status === 500) {
        setResultFound(false);
      } else if (res) {
        setPortList(res);
        setResultFound(true);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const onClickSearch = () => {
    if (airline && airportName) {
      handleGetPort();
    } else if (airline) {
      handleGetPorts();
    }
  }

  // temporary fix for #FCDOP3-1261
  // The best approach should be to hierarchically structure the paths and, upon clicking a sidebar item,
  // navigate the path back to the root node.
  // However, the current usage of react-router-dom does not support this flexible redirect method.
  // Let's refactoring it later
  useEffect(() => {
    const completePath = `${location.pathname}${location.search}`;
    if (path && completePath !== path && path !== '/notam/port?airline=CPA') {
      setAirportName('');
      setLinkSearch('');
      handleGetPorts();
    }
    if (path && path === '/notam/port?airline=CPA') {
      setShowList(true);
    }
  }, [location]);

  useEffect(() => {
    if (linkSearch) {
      handleGetPort();
    }
  }, [linkSearch]);

  useEffect(() => {
    onClickSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateNotam = (type: string, editValue: any, tabIndex: number, secIndex: number, secItemIndex: number, valueIndex: number) => {
    if (type === 'edit') {
      portModal.tabs[tabIndex].secs[secIndex].items[secItemIndex].value[valueIndex] = editValue;
      dispatchReducer({ type: "NO_SAVE_LOAD", port: portModal });
    } else if (type === 'add') {
      if (portModal.tabs[tabIndex].secs[secIndex].items[secItemIndex].value && portModal.tabs[tabIndex].secs[secIndex].items[secItemIndex].value.length) {
        portModal.tabs[tabIndex].secs[secIndex].items[secItemIndex].value.push("");
      } else {
        portModal.tabs[tabIndex].secs[secIndex].items[secItemIndex].value = [""];
      }
      dispatchReducer({ type: "NO_SAVE_LOAD", port: portModal });
    } else if (type === 'del') {
      if (window.confirm(`Are you sure to delete this Notam?`)) {
        portModal.tabs[tabIndex].secs[secIndex].items[secItemIndex].value.splice(valueIndex, 1);
      }
      dispatchReducer({ type: "NO_SAVE_LOAD", port: portModal });
    }
    // onSavePort();
  }

  const blurPrefix = (content: any, tabIndex: number, secIndex: number, secItemIndex: number, isCopy: boolean) => {
    const ORIGINAL_VAL = portModal.tabs[tabIndex].secs[secIndex].items[secItemIndex].value || '',
          VALUE_DIVIDER = "__",
          ADOPTED_NEW_FORMAT = checkIfValueAdoptNewFormat(ORIGINAL_VAL, VALUE_DIVIDER);

    const valArr = (ADOPTED_NEW_FORMAT)? ORIGINAL_VAL.split('__') : ORIGINAL_VAL.split(' ');
    const val1 = valArr.length > 0 ? valArr[0] : '';
    const val2 = valArr.length > 1 ? valArr[1] : '';
    if (isCopy) {
      portModal.tabs[tabIndex].secs[secIndex].items[secItemIndex].value = val1 + VALUE_DIVIDER + content;
    } else {
      portModal.tabs[tabIndex].secs[secIndex].items[secItemIndex].value = content + VALUE_DIVIDER + val2;
    }
    onSavePort();

    function checkIfValueAdoptNewFormat($concatOriginalValue: string, $divider: string): boolean{
      return $concatOriginalValue.includes($divider);
    }
  }

  const onConfirmSave = () => {
    if (JSON.stringify(portModal.tabs) !== JSON.stringify(originalPort.tabs)) {
      onSavePort();
    }
    setIsEditMode(false);
  }

  const onSavePort = () => {
    const tempPort = {
      airline: portModal.airline,
      iata: portModal.iata,
      icao: portModal.icao,
      type: "section",
      cityName: portModal.cityName,
      countryName: portModal.countryName,
      galacxyId: userId,
      tabs: portModal.tabs,
      createdBy: userId,
      name: portModal.name,
    }

    postSavePortElement(tempPort).then((res) => {
      dispatchReducer({ type: "LOAD", port: res });
    }).catch((err) => {
      console.log(err);
    });
  }

  const onCreateNewPort = () => {
    postCreateNewPort(newPortForm).then((res) => {
      if (res.status) {
        throw res;
      } else {
        setIsNewPortModal(false);
        dispatchReducer({ type: "LOAD", port: res });
        setResultFound(true);
        setIsSearchPort(true);
        setIsSearchPorts(false);
        setIsViewRunway(false);
      }
    }).catch((err) => {
      if (err.status === 400 && err.detail.trim().toLowerCase() === "duplicate key") {
        alert("Please note that one of the Airport name/IATA code/ICAO code was existing!");
      }
      console.log(err);
    });
  }

  const onSaveTabs = () => {
    let tempTabs: IPortTab[] = [];
    if (tabsRef && tabsRef.current) {
      const tabsState = tabsRef.current.getTabsState();
      tempTabs = [...tabsState];
    }

    const tempPort = {
      airline: portModal.airline,
      iata: portModal.iata,
      icao: portModal.icao,
      type: "tab",
      cityName: portModal.cityName,
      countryName: portModal.countryName,
      galacxyId: userId,
      tabs: tempTabs,
      createdBy: userId,
      name: portModal.name,
    }

    postSavePortElement(tempPort).then((res) => {
      dispatchReducer({ type: "LOAD", port: res });
    }).catch((err) => {
      console.log(err);
    });

    closeModal();
  }

  const onCancelEdit = () => {
    if (JSON.stringify(portModal.tabs) != JSON.stringify(originalPort.tabs)) {
      setIsSaveReminder(true);
    } else {
      dispatchReducer({ type: "RESET" });
      setIsEditMode(false);
      setIsEditTabModal(false);
    }
  }

  const onConfirmCancelEdit = () => {
    dispatchReducer({ type: "RESET" });
    setIsEditMode(false);
    setIsEditTabModal(false);
    setIsSaveReminder(false);
  }

  const downloadFile = (id: string, name: string) => {
    const request = {
      airline: portModal.airline,
      icao: portModal.icao,
      galacxyId: userId,
      fileId: id
    }

    getPortAttachment(request).then((res) => {
      const blobFile = new Blob([res], { type: res.type })
      const fileURL = URL.createObjectURL(blobFile);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = name;
      alink.target = "_blank";
      alink.click();
    }).catch((err) => {
      console.log(err);
    });
  }

  const onViewRunwayClick = () => {
    setIsViewRunway(true);
    setResultFound(true);
    setIsSearchPort(false);
    setIsSearchPorts(false);
  }

  const onViewNotamClick = () => {
    setIsSearchPort(true);
    setResultFound(true);
    setIsSearchPorts(false);
    setIsViewRunway(false);
  }

  const portPageRunwayProps = {
    portDetail: {
      airline: originalPort?.airline,
      icao: originalPort?.icao,
      cityName: originalPort?.cityName,
      countryName: originalPort?.countryName,
    },
  };

  const onLinkPort = (port: string) => {
    setLinkSearch(port);
  }

  const back = () => {
    setShowList(true);
  }

  const renderPortList = () => {
    return portList ? (
      <div style={{ border: "1px solid #b9bcc0", padding: 20, borderRadius: 10, margin: 0 }}>
      <CRow>
        <CCol>
          <CTable hover>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">
                Airline
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                IATA code
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                ICAO code
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                Country Name
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                City name
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">
                Airport name
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              {!!portList.ports && portList.ports.length !==0?
                  portList.ports.map((port, index) =>
                  <PortListTableItem key={`PortListTableItem_${index}`} index={index} port={port} onLinkPort={onLinkPort}/>)
                  :
                  (<NoDataTableItem colSpan={3} message={"There is port page Data"} />)
              }
            </CTableBody>
          </CTable>
        </CCol>
      </CRow>
      </div>
      ) : null;
  }

  return (
    <>
      <div className={"CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>Port Page</div>
      <CRow className={"p-2 ms-auto ps-0"}>
        <CCol sm={1} className="ps-0">
          <CXDropDown
            value={airline}
            placeholder={"Airline"}
            onChange={setAirline}
            options={airlineOptions}
            zIndex={1000} />
        </CCol>
        <CCol sm={3}>
          <CXTextInput placeholder={"Airport name/ IATA code/ ICAO code"} value={airportName} onChange={setAirportName} zIndex={1029} />
        </CCol>
        <CCol sm={2} className={isEditMode ? "port-button" : ""}>
          <CXButton text={"Search"} onClick={() => { onClickSearch() }} fontSize={"16px"} width={"100%"} disabled={isEditMode} />
        </CCol>
        <CCol sm={4}></CCol>
          <Access route={routePath} access={[AccessType.CREATE]}>
            <CCol sm={2} className={`${isEditMode ? "port-button" : ""} port-add-new-col ps-0`}>
                <CXButton outline={true} text={"Add new port"} onClick={() => setIsNewPortModal(true)} disabled={isEditMode} fontSize={"16px"} width={"100%"} />
            </CCol>
          </Access>
        {!showList && <Link to ='/notam/port' onClick={() => {back()}} style={ { fontSize: "20px" , marginTop: "30px", color: "#11709A", marginBottom: 0, cursor:'pointer'}}>
          {"< Back to full list"}
        </Link> }
      </CRow>

      {showResultNotFound &&
        <CRow className={"p-2 ms-auto"}>
          <span className={"CathaySansRg text-center"} style={{ fontSize: "25px", height: "200px", fontWeight: 'bold', marginTop: "5%" }}> {resultNotFoundContent}</span>
        </CRow>
      }

      {showPortPageBar &&
        <div className="port-page-bar">
          <CRow className={"p-2 ms-auto"}>
            <CCol sm={2} className='bar-heading' style={{ alignSelf: "center" }}>
              <span> {portModal.iata || "--"} / {portModal.icao || "--"}</span>
            </CCol>
            <CCol sm style={{ alignSelf: "center" }}>
              <ViewLabel label="Airline" value={portModal.airline} valueFont={'bar-label-value'}></ViewLabel>
            </CCol>
            <CCol sm style={{ alignSelf: "center" }}>
              <ViewLabel label="Airport Name" value={portModal.icao} valueFont={'bar-label-value'}></ViewLabel>
            </CCol>
            <CCol sm style={{ alignSelf: "center" }}>
              <ViewLabel label="City Name" value={portModal.cityName} valueFont={'bar-label-value'}></ViewLabel>
            </CCol>
            <CCol sm style={{ alignSelf: "center" }}>
              <ViewLabel label="Country Name" value={portModal.countryName} valueFont={'bar-label-value'}></ViewLabel>
            </CCol>
            <CCol sm style={{ alignSelf: "center" }} className='view-info-notam'>
              <span onClick={() => { onViewNotamClick() }}>
                <ViewLabel label="Notam" value='View Info' valueFont={'bar-label-value'} valueStyle={isViewRunway ? { color: "#116f9a", cursor: "pointer" } : { color: "#4C4C4C", cursor: "default" }}></ViewLabel>
              </span>
            </CCol>
            <CCol sm style={{ alignSelf: "center" }}>
              <span onClick={() => { onViewRunwayClick() }}>
                <ViewLabel label="Runway" value='View Info' valueFont={'bar-label-value'} valueStyle={isViewRunway ? { color: "#4C4C4C", cursor: "default" } : { color: "#116f9a", cursor: "pointer" }}></ViewLabel>
              </span>
            </CCol>
          </CRow>
        </div>
      }

      {showPortContent &&
        <Tab.Container id="left-tabs-example" defaultActiveKey={0} onSelect={(key) => setTabKey(key ? parseInt(key) : 0)}>
          <CRow className='tabRow ps-0'>
            <CCol sm={10} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Nav variant="pills" className={"p-2 ps-0"}>
                {portModal.tabs && portModal.tabs.map((tab, index) =>
                  <Nav.Item key={`TabName_${tab.id}_${tab.name}`}>
                    <Nav.Link className={`${tabKey == index ? "selectedTab" : "tabButton"}`} eventKey={index}>{tab.name}</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
              {isEditMode &&
                <Form.Label className="editTab" onClick={() => setIsEditTabModal(true)}>Edit tabs</Form.Label>
              }
            </CCol>
            <Access route={routePath} access={[AccessType.UPDATE]}>
              <CCol sm={2} className={`${isEditMode ? "port-button" : ""} port-add-new-col ps-0`}>
                  <CXButton outline={true} text={"Edit"} onClick={() => setIsEditMode(true)} disabled={isEditMode} fontSize={"16px"} width={"100%"} />
              </CCol>
            </Access>
          </CRow>
          <CRow className='infoBorderRow'>
            <CCol sm={6} >
              <AlertIcon style={{ backgroundColor: "white", height: "2.4rem", width: '2.4rem', margin: "10px 0 0 20px", position: "absolute" }} />
              <span style={{ fontSize: "16px", fontFamily: "CathaySans_Bd", color: "#4C4C4C", fontWeight: "700", lineHeight: "20px", position: "absolute", margin: "22px 0 0 70px" }} >
                Sub-bullet points can only be input in the next indent level
              </span>
              <span style={{ fontSize: "16px", fontFamily: "CathaySans_Bd", color: "#7C7C7C", fontWeight: "400", lineHeight: "20px", position: "absolute", margin: "42px 0 0 70px" }} >
                The bullet points that skip one or more indent level will not be able to display.
              </span>
            </CCol>
          </CRow>
          <CRow className='infoRow'>
            <CCol sm={2} >
              <span style={{ fontSize: "20px", fontFamily: "CathaySans_Bd", color: "#000000", fontWeight: "700", lineHeight: "28px" }} >
                V{portModal.version}
              </span>
            </CCol>
            <CCol sm={4} >
              <span style={{ fontSize: "18px", fontFamily: "CathaySans_Rg", color: "#4C4C4C", fontWeight: "400", lineHeight: "28px", position: "absolute", right: "55px" }} >
                Last updated at {portModal.createdAt} by <span style={{ fontFamily: "CathaySans_Bd", color: "#000000", fontWeight: "700" }} >{portModal.createdBy}</span>
              </span>
            </CCol>
          </CRow>
          <CRow>
            {portModal.tabs && portModal.tabs.map((tab, index) =>
              <Tab.Content key={`TabContent_${tab.id}`}>
                <Tab.Pane eventKey={index}>
                  {tab.name != "Attachment" ?
                    <DndProvider backend={HTML5Backend}>
                      <PortPageContentContainer
                        portTabSecs={tab.secs}
                        isEditMode={isEditMode}
                        tabId={tab.id}
                        tabIndex={index}
                        dispatchReducer={dispatchReducer}
                        portInfo={portInfo}
                        blurPrefix={blurPrefix}
                        updateNotam={updateNotam}
                        setShowList={setShowList}
                      />
                    </DndProvider>
                    :
                    <PortPageAttachmentContainer
                      portTabContents={tab.secs}
                      isEditMode={isEditMode}
                      tabIndex={index}
                      dispatchReducer={dispatchReducer}
                      downloadFile={downloadFile}
                      portInfo={portInfo} />
                  }
                </Tab.Pane>
              </Tab.Content>
            )
            }
            {isEditMode &&
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Cancel"} onClick={() => onCancelEdit()} fontSize={"16px"} width={"100%"} /></div>
                <div style={{ bottom: "40px", right: "40px", width: "200px", marginLeft: "15px" }}><CXButton text={"Save"} onClick={() => onConfirmSave()} fontSize={"16px"} width={"100%"} /></div>
              </div>
            }
          </CRow>
        </Tab.Container>
      }
      {/* Edit tabs */}
      <Modal className="portModal" show={isEditTabModal} onHide={closeModal} size="lg">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>Edit Tabs for</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DndProvider backend={HTML5Backend}>
            <DragContainer
              ref={tabsRef}
              editingTabs={portModal.tabs}
              dispatchReducer={dispatchReducer} />
          </DndProvider>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Cancel"} onClick={() => onCancelEdit()} fontSize={"16px"} width={"100%"} /></div>
          <div style={{ bottom: "40px", right: "40px", width: "200px" }}><CXButton text={"Save"} onClick={() => onSaveTabs()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>


      {/* New port */}
      <Modal className="portModal" show={isNewPortModal} onHide={closeModal} size="lg">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>Add new port</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            <CCol xs={6}>
              New port information
            </CCol>
            <CCol xs={6}>
              <CRow>
                <CCol xs={6}>
                  <CXTextInput placeholder={"IATA code"} value={newPortForm.iata} onChange={setNewPortForm} onChangeField={onChangeField} field="iata" state={newPortForm} zIndex={1029} />
                </CCol>
                <CCol xs={6}>
                  <CXTextInput placeholder={"ICAO code"} value={newPortForm.icao} onChange={setNewPortForm} onChangeField={onChangeField} field="icao" state={newPortForm} zIndex={1029} />
                </CCol>
              </CRow>
              <CRow>
                <CXTextInput placeholder={"Airport name"} value={newPortForm.name} onChange={setNewPortForm} onChangeField={onChangeField} field="name" state={newPortForm} zIndex={1029} />
              </CRow>
              <CRow>
                <CCol xs={6}>
                  <CXTextInput placeholder={"City name"} value={newPortForm.cityName} onChange={setNewPortForm} onChangeField={onChangeField} field="cityName" state={newPortForm} zIndex={1029} />
                </CCol>
                <CCol xs={6}>
                  <CXTextInput placeholder={"Country name"} value={newPortForm.countryName} onChange={setNewPortForm} onChangeField={onChangeField} field="countryName" state={newPortForm} zIndex={1029} />
                </CCol>
              </CRow>
              <CRow>
                <CXDropDown
                  value={newPortForm.airline}
                  placeholder={"Airline"}
                  onChange={setNewPortForm}
                  onChangeField={onChangeField}
                  field="airline"
                  state={newPortForm}
                  options={airlineOptions}
                  zIndex={1029} />
              </CRow>
            </CCol>
          </CRow>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Cancel"} onClick={() => closeModal()} fontSize={"16px"} width={"100%"} /></div>
          <div style={{ bottom: "40px", right: "40px", width: "200px" }}><CXButton text={"Add"} onClick={() => onCreateNewPort()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>

      {/* Save reminder */}
      <Modal className="portReminderModal" show={isSaveReminder} onHide={closeModal} size="lg">
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>Are you sure to cancel ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRow>
            The changes made will be lost as they are not saved.
          </CRow>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => setIsSaveReminder(false)} fontSize={"16px"} width={"100%"} /></div>
          <div className='save-button'><CXButton text={"Confirm"} onClick={() => onConfirmCancelEdit()} fontSize={"16px"} width={"100%"} /></div>
        </Modal.Footer>
      </Modal>
      {isViewRunway &&
        <div className='port-page-runway'>
          <PortPageRunway {...portPageRunwayProps} />
        </div>
      }
      {isSearchPorts && renderPortList()}
    </>
  );
};

export default PortPageNotamPage;
