import React from 'react';
import {CSidebar, CSidebarNav} from "@coreui/react";
import {useAppSelector} from "../app/hooks";
import {selectApp} from "../slice/appSlice";
import {ISideBarObject} from "../types/app";
import AppSidebarSection from './AppSideBarSection';
import { DISPATCHER_PATHS, SIM_PATHS, UO_PATHS, FLIGHT_PLAN_CONFIG_PATHS, CLOSE_FLIGHT_PATHS, FLIGHT_POLICY_PATHS, DATA_CONFIGURATION_PATHS } from '../constants/path';// Internal_Support_PATHS, 
import { getAccessibleNavData } from '../utils/roleAccessHelper';
import { selectAuth } from '../slice/authSlice';

const AppSidebar = () => {
    const { userGroupIDs } = useAppSelector(selectAuth);
    const { isSidebarVisible } = useAppSelector(selectApp);

    const adminNavData: Array<ISideBarObject> = [{
        name: "Dashboard",
        link: DISPATCHER_PATHS.flightPlan,
    }, {
        name: "Status",
        link: DISPATCHER_PATHS.status,
    }];

    const flightPolicyNavData: Array<ISideBarObject> = [
    /* hide - Only apply to ete env for now - ref task 2655
    {
        name: "Fuel policies",
        link: FLIGHT_POLICY_PATHS.fuelPolicy,
    }, */
    {
        name: "No fly zones",
        link: FLIGHT_POLICY_PATHS.noFlyZonesPolicy,
    }, {
        name: "Tankering policies",
        link: FLIGHT_POLICY_PATHS.tankeringPolicy,
    }, {
        name: "Volcanic ash",
        link: FLIGHT_POLICY_PATHS.volcanicAshesPolicy,
    }];

    const flightPlanConfigNavData: Array<ISideBarObject> = [
            {
            name: "Appendix Rules",
            link: FLIGHT_PLAN_CONFIG_PATHS.appendix,
        },
        {
            name: "SNN",
            link: FLIGHT_PLAN_CONFIG_PATHS.snn,
        },
        // {
        //     name: "Airport Information",
        //     link: FLIGHT_PLAN_CONFIG_PATHS.airport,
        // },
        {
            name: "Aircraft Information",
            link: FLIGHT_PLAN_CONFIG_PATHS.aircraft,
        },
        // {
        //     name: "Company Notam (NSU)",
        //     link: FLIGHT_PLAN_CONFIG_PATHS.companyNotam,
        // }
        {
            name: "Port Page Notam",
            link: FLIGHT_PLAN_CONFIG_PATHS.portPageNotam,
        },
        {
            name: "Defects",
            link: FLIGHT_PLAN_CONFIG_PATHS.defects,
        }, {
            name: "NSU / CO NOTAM",
            link: FLIGHT_PLAN_CONFIG_PATHS.nsuNotam,
        }, {
            name: "OE NOTAM",
            link: FLIGHT_PLAN_CONFIG_PATHS.oeNotam,
        },
        {
            name: "Dep & Arr - Crew Feedback",
            link: FLIGHT_PLAN_CONFIG_PATHS.feedback,
        },
        // {
        //     name: "Data Page",
        //     link: FLIGHT_PLAN_CONFIG_PATHS.data,
        // }
        {
            name: "SID/STAR data",
            link: FLIGHT_PLAN_CONFIG_PATHS.sidStar,
        },
    ];

    const closeFlightNavData: Array<ISideBarObject> = [{
        name: "Closed flight",
        link: CLOSE_FLIGHT_PATHS.closeFlight,
    }];

    const dataNavData: Array<ISideBarObject> = [{
        name: 'CAR report topic',
        link: DATA_CONFIGURATION_PATHS.carReportTopic
    },{
        name: "CAR report email",
        link: DATA_CONFIGURATION_PATHS.carReportEmail,
    },
    // {
    //     name: "SID/STAR data",
    //     link: DATA_CONFIGURATION_PATHS.cxSidStar,
    // }
    ];

    const uoDataNavData: Array<ISideBarObject> = [{
        name: 'CAR report topic',
        link: DATA_CONFIGURATION_PATHS.uoCarReportTopic
    },{
        name: "CAR report email",
        link: DATA_CONFIGURATION_PATHS.uoCarReportEmail,
    },{
        name: "SID/STAR data",
        link: DATA_CONFIGURATION_PATHS.uoSidStar,
    }];

    const simNavData: Array<ISideBarObject> = [{
        name: "SIM OFP",
        link: SIM_PATHS.landing,
    }, {
        name: "Template Configuration",
        link: SIM_PATHS.simTemplateConfiguration,
    }];

    const uoNavData: Array<ISideBarObject> = [{
        name: "Dashboard",
        link: UO_PATHS.flightPlan,
    }, {
        name: "Closed flight",
        link: UO_PATHS.closeFlight,
    }, 
    // {
    //     name: "SNN Rules",
    //     link: UO_PATHS.snnRule,
    // },
    {
        name: "SNN",
        link: FLIGHT_PLAN_CONFIG_PATHS.hkeSnn,
    },{
        name: "Fuel Advisory",
        link: UO_PATHS.fuelAdv,
    }, {
        name: "Defects",
        link: UO_PATHS.defect,
    }, {
        name: "Crewlist",
        link: UO_PATHS.crewlist,
    }, {
        name: "NSU / CO NOTAM",
        link: UO_PATHS.nsuNotam,
    }, {
        name: "OE Notam",
        link: UO_PATHS.oeNotam,
    }, {
        name: "Appendix Rule",
        link: UO_PATHS.appendixRule,
    }];
        
    const interSupData: Array<ISideBarObject> = [];

    return <CSidebar className={"sideBar"} visible={isSidebarVisible} overlaid={false} position="fixed"
                     style={{paddingTop: "64px"}}>
        <CSidebarNav>
            {<AppSidebarSection navTitle={"Monitoring"} navData={getAccessibleNavData(adminNavData, userGroupIDs)} />}
            {<AppSidebarSection navTitle={"Active flight policies"} navData={getAccessibleNavData(flightPolicyNavData, userGroupIDs)} />}
            {<AppSidebarSection navTitle={"Flight Plan Configuration"} navData={getAccessibleNavData(flightPlanConfigNavData, userGroupIDs)} />}
            {<AppSidebarSection navTitle={"Data Configuration"} navData={getAccessibleNavData(dataNavData, userGroupIDs)} />}
            {<AppSidebarSection navTitle={"Closed Flight"} navData={getAccessibleNavData(closeFlightNavData, userGroupIDs)} />}
            {<AppSidebarSection navTitle={"SIM"} navData={getAccessibleNavData(simNavData, userGroupIDs)} />}
            {<AppSidebarSection navTitle={"HKE"} navData={getAccessibleNavData(uoNavData, userGroupIDs)} />}
            {<AppSidebarSection navTitle={"Internal Support"} navData={getAccessibleNavData(interSupData, userGroupIDs)}/>}
            {<AppSidebarSection navTitle={"HKE Data Configuration"} navData={getAccessibleNavData(uoDataNavData, userGroupIDs)} />}
        </CSidebarNav>
    </CSidebar>
};

export default AppSidebar;
